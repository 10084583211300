@font-face{

  font-family: 'Daray';

  src: 
  
  url('/src/main/fonts/Daray.ttf') format('truetype');
  
  font-weight: normal;
  
  font-style: normal;
  
  }

  @font-face{

    font-family: 'Okuda';
  
    src: 
    
    url('/src/main/fonts/Okuda.ttf') format('truetype');
    
    font-weight: normal;
    
    font-style: normal;
    
    }
    @font-face{

      font-family: 'St.Sign';
    
      src: 
      
      url('/src/main/fonts/St.Sign.ttf') format('truetype');
      
      font-weight: normal;
      
      font-style: normal;
      
      }

html,
body,
#root {
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell,
    'Open Sans', 'Helvetica Neue', sans-serif;
  line-height: 2em;
  background: #e8f1f1;
  text-align: center;
  margin: 0;
  color: #515252;
}
.wrapper {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 0px;
  grid-auto-rows: minmax(30px, auto);
  width: 98%;
  margin: auto;
  background: white;
  font-size: 8pt;
  font-weight: 500;
}

.title{
  margin-left: 20px;
  font-size: 18px;
  font-weight: 400;
}

.wrapper div{
  border: 1px solid black;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}



.wrapper div.item15{
  border-left: 3px solid black;
  font-size: 12px;
}

.wrapper div.item16{
  font-size: 12px;
  font-weight: bold;
}

.wrapper div.maximum{
  background-color: #4ec0e4;
}

.cell-btn{
  background: #7f7ff7;
  color: white;
  border: none;
  padding: 5px;
  border-radius: 50%;
  font-size: 15px;
  height: 30px;
  width: 30px;
  text-align: center;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.form {
  text-align: center;
  margin-top: 10vh;
  width: 60%;
    max-width: 700px;
    padding: 30px;
    border-radius: 10px;
}

@media screen and (max-width: 900px) {
  .form {
  width: 100%;
  }
}

.form-small{
  margin: 50px 120px;
}

.anticon-spin svg{
    width: 100px;
    height: 100px;

}

.ant-spin .ant-spin-dot{
  width: 100px;
  height: 100px;
  margin: 100px;
}

.small-spin .anticon-spin svg{
  width: 30px;
  height: 30px;

}

.small-spin .ant-spin .ant-spin-dot{
  width: 30px;
  height: 30px;
  margin: 30px;
}

.flex-container{
  display: flex; 
  justify-content: center;
}

.left-page-block{
  display: grid;
  width: 400px;
  margin-left: 15px;
  display: grid;
}

.right-page-block{
  width: 100%;
  display: grid;
}

.date-string{
  margin-bottom: 20px;
  margin-left: 23px;
}

.page-loading{
  width: 100%;
  padding-left: 280px;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  padding-top: 25vh;
}

.form-group{
  margin-bottom: 20px;
}


.login__input{
    line-height: 40px;
    margin-top: 30px;
    font-size: 14px;
    -webkit-appearance: none;
    background-color: #dbf0ee;
    background-image: none;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
    color: #606266;
    height: 40px;
    line-height: 40px;
    outline: 0;
    padding: 0 15px;
    -webkit-transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
    transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.invalid-feedback{
  color: red;
  font-size: 15px;
}
.form-label{
  font-size: 17px;
  font-weight: 500;
  color: #515252;
}

foreignObject{
  line-height: initial;
  text-align: center;
  overflow-wrap: break-word;
  font-family: "St.Sign";
}

#invoice {
  overflow: hidden;
}

.transform {
  transform: rotate(90deg);
}

.controls {
  position: absolute;
  margin: 10px;
}

.controls button {
  background: #dbf0ee;
  border: none;
    border-radius: 2px;
    margin: 8px;
    padding: 5px 10px;
}

select{
  background-color: #dbf0ee;
  background-image: none;
  border-radius: 4px;
  border: 1px solid #DCDFE6;
  color: #606266;
  height: 25px;
  line-height: 40px;
  outline: 0;
  padding: 0 15px;
  transition: border-color 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
  margin-top: 10px;
}

.container{
  margin: 0px 30px;
  margin-bottom: 30px;
}

.grid5{
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  gap: 20px;
}

.flex{
  display: flex;
  gap: 10px;
  align-items: center;
}

.flex-chart{
  display: flex;
  gap: 20px;
  /*align-items: center;*/
  width: calc(100% - 20px);
  margin-top: 15px;
}

.flex-chart .white-block {
  width: -webkit-fill-available;
  padding-bottom: 0;
}

.flex-chart h3 {
  margin: 20px;
  text-align: left;
}

.flex-space{
  justify-content: space-between;
}

.ant-menu::before{
  display: none;
}

.ant-menu-root{
  position: fixed;
  z-index: 100;
  top: 62px!important;
  /*margin-top: 62px;*/
  left: 0;
  bottom: 0;
  min-width: 260px; 
  min-height: 120vh;

  background-color: #f2f5fa;
  box-sizing: border-box;
  transition: left .3s;
}

.info-cell{
  /*padding: 0 10px;*/
  padding-bottom: 10px;
  background: white;
  margin: 20px auto;
}

.ant-tabs-nav-list{
  padding: 0 10px;
}

.flex-center{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px;
    gap: 20px;
}

svg{
  height: 17px;
  width: 17px;
}

.ant-table-filter-column svg, .ant-table-column-sorters svg{
  height: unset;
  width: unset;
}

.cell-btn svg{
  fill:rgb(255, 253, 253);
  transition: 1s;
}

.lable{
  text-align: left;
}

.input-item{
  width: 29%;
  min-width: 420px;
  height: 95px;
}
.error{
  color: #ff2602;
  font-size: 12px;
  text-align: left;
  line-height: 15px;
  max-width: 360px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

h2, h3{
  margin: 0;
}

.upload{
  margin: 20px auto;
  width: 100%;
}

.create-event-modal-form{
  text-align: left;
}

.ant-modal{
  width: 50%;
}

.modal-input-item{
  text-align: left;
  margin-bottom: 10px;
}

form .modal-input-item{
  min-height: 70px;
}

.ant-modal-wrap .ant-modal .ant-modal-content, .ant-modal-title{
  background-color:#ebeff7;
}

.flex .modal-input-item, .flex .modal-input-item .ant-input-number, .flex .modal-input-item .ant-select {
  width: 100%;
}

.ant-input, .ant-select-single .ant-select-selector, .ant-picker, .ant-select-single, .ant-input-number .ant-input-number-input, .ant-input-number, .ant-input-number-input, .ant-btn, .ant-input-affix-wrapper {
  border-radius: 0;
  /*height: 40px;*/
}

.visitor-form .ant-input, .visitor-form .ant-select-single .ant-select-selector, .visitor-form .ant-picker, .visitor-form .ant-select-single,.visitor-form .ant-input-number .ant-input-number-input,.visitor-form .ant-input-number,.visitor-form .ant-input-number-input,.visitor-form .ant-btn {
  border-radius: 0;
  height: 40px;
}

.ant-input-group-wrapper.ant-input-search{
  width: unset;
}

.ant-input-search .ant-input-group .ant-input-affix-wrapper:not(:last-child), .ant-input-search >.ant-input-group >.ant-input-group-addon:last-child .ant-input-search-button{
  border-radius: 0;
}

.tab-upload .ant-upload-list {
  top: -39px;
  background: white;
  margin: 10px;
  max-width: 320px;
  display: none;
}

.tab-upload .ant-upload-list-item-name{
  max-width: 320px;
}

.ant-notification .ant-notification-notice {
  text-align: left;
}

.red{
  color: red;
}

.mini-tab{
  padding: 3px 8px;
  background: #c4d5ee;
  margin: 3px 0;
  border-radius: 10px;
  display: flex;
  align-items: center;
}
.mini-tab svg{
  cursor: pointer;
  width: 20px;
  height: 20px;
  margin-left: 10px;
}

.mini-tab svg:hover, h3 svg:hover{
  fill: #3030f7;
}

.max-width{
  width: -webkit-fill-available;
}

.mini-tabs.flex{
  flex-wrap: wrap;
  gap: 2px 5px;
}

.link{
  cursor: pointer;
  display: flex;
  align-items: center;
}

.link:hover{
  color: #7979fa;
  transition: 1s;
  cursor: pointer;
  /*text-decoration: underline;*/
}

.ant-menu .btm{
  position: absolute;
  bottom: 35px;
  color: black;
}

.ant-menu-inline.ant-menu-root .ant-menu-item >.ant-menu-title-content, .ant-menu-inline.ant-menu-root .ant-menu-submenu-title >.ant-menu-title-content{
  text-align: left;
}

.flex-start{
  align-items: flex-start;
}

.box{
  /*margin: 15px;*/
  width: 100%;
  margin-top: 62px;
  margin-left: 280px;
}

.ant-menu-root > .ant-menu-item:last-child {
  position: absolute;
  bottom: 90px;
}


.ant-menu-root .ant-menu-item {
  height: 35px;
  line-height: 30px;
}

.ant-menu-sub .ant-menu-item {
  height: 25px;
  line-height: 15px;
  font-size: 12px;
  background: white;
  z-index: 100;
  padding-left: 35px!important;
}

.ant-menu-sub {
  overflow-y: scroll;
  height: 30vh;
}

.menu-container{
  position:relative;
  /*width: 365px;*/
  height: 100vh;
}

.ant-tabs-nav-operations {
  display: none!important;
}

.uploded::-moz-placeholder { color: #1677ff; }


.uploded::-webkit-input-placeholder { color: #1677ff; }

.left-menu-contacts{
    z-index: 100;
    text-align: left;
    position: fixed;
    line-height: normal;
    font-size: 12px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
    padding-left: 15px;
    padding-right: 8px;
    padding-bottom: 10px;
    bottom: 0;
    background-color: #f2f5fa;
    box-sizing: border-box;
    transition: left .3s;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
    background: white;
    width: 265px;
    margin-left: 15px;
}

.left-menu-contacts a{
  color: #001529;
  text-decoration: none;
}

.ant-menu-light .ant-menu-item-selected .ant-menu-item-icon path{
  fill: #1677ff;
}

.ant-menu-item-disabled path{
  fill: rgba(0, 0, 0, 0.25)!important;
}

.ant-menu-title-content h2{
  margin-top: 10px;
  display: block;
  text-wrap: wrap;
  line-height: 22px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.ant-table-wrapper .ant-table-cell, .ant-table-wrapper .ant-table-thead>tr>th{
  padding: 5px;
}

.ant-table-wrapper .ant-table{
  font-size: 12px;
}

.top-menu {
  position: fixed;
  z-index: 101;
  top: 0;
  left: 0;
  right: 0;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
  display: flex;
  height: 50px;
  white-space: nowrap;
  background: #001529;
  box-shadow: 0 8px 24px -2px rgba(0,0,0,.05);
  width: auto;
  color: white;
}

.ant-menu-light.ant-menu-horizontal >.ant-menu-item:hover::after{
  border: none;
}

.ant-menu-light.ant-menu-horizontal >.ant-menu-item:hover{
  color: white!important;
  cursor: auto;
}

.ant-menu-inline.ant-menu-root, .ant-menu{
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.ant-menu-light .ant-menu-item-selected, .ant-menu-light>.ant-menu .ant-menu-item-selected {
  color: #1677ff;
  border-right: 3px solid #1677ff;
}

.ant-menu-inline .ant-menu-item, .ant-menu-vertical .ant-menu-item, .ant-menu-inline .ant-menu-submenu-title, .ant-menu-vertical .ant-menu-submenu-title {
  margin-inline: 0;
  margin-block: 0;
  width: auto;
}

.ant-menu .ant-menu-item, .ant-menu .ant-menu-submenu, .ant-menu .ant-menu-submenu-title {
  border-radius: 0px;
}

.logout svg, .logout{
  width: 30px;
  height: 30px;
}

.logout svg:hover{
  fill:#1677ff;
}

.header {
  top: 80px;
  left: 300px;
  right: 0;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  text-align: left;
  /*height: 64px;*/
  white-space: nowrap;
  background: white;
  box-shadow: 0 8px 24px -2px rgba(0,0,0,.05);
  margin-bottom: 15px;
}

.btn{
  display: flex;
  align-items: center;
  border-radius: 0;
  margin: inherit;
}

.ant-table-wrapper{
  margin-top: 15px;
  width: calc(100vw - 301px);
}

/* Works on Firefox */
* {
  scrollbar-width: thin;
  scrollbar-color: #6ca9ff rgb(236, 234, 233);
}

/* Works on Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 12px;
  height: 12px;
}

*::-webkit-scrollbar-track {
  background: rgb(236, 234, 233);
}

*::-webkit-scrollbar-thumb {
  background-color: #6ca9ff;
  border-radius: 20px;
  border: 3px solid rgb(236, 234, 233);
}

.ant-table-wrapper .ant-table-container table>thead>tr:first-child >*:first-child, .ant-table-wrapper .ant-table-container table>thead>tr:first-child >*:last-child {
  border-start-start-radius: 0px;
  border-start-end-radius: 0px;
}

.ant-pagination .ant-pagination-item {
  border-radius: 0;
}

.ant-table-wrapper .ant-table-cell, .ant-table-wrapper .ant-table-tbody>tr>td{
  padding: 0 15px;
  height: 45px;
}

.ant-tag {
  color: rgba(0, 0, 0, 0.88);
  margin: 5px 0;
}

.tab-label{
  width: 30%;
  text-align: right;
  height: 40px;
  display: flex;
  justify-content: right;
  align-items: center;
}

.ant-radio-group{
  text-align: left;
  display: grid;
}

.modal-input-item .ant-radio-group{
  display: flex;
}

.ant-radio-button-wrapper:first-child {
  border-start-start-radius: 0px;
  border-end-start-radius: 0px;
}

.ant-radio-button-wrapper:last-child {
  border-start-end-radius: 0px;
  border-end-end-radius: 0px;
}

.right-block{
  display: flex;
  justify-content: end;
}

fieldset textarea{
  font-size: 12px;
}

.group{
  margin-top: 30px;
}

.white-block{
  background: white;
  /*margin-bottom: 15px;
  padding: 10px;*/
  margin: 0;
  padding-bottom: 20px;
}

.white-block-content{
  padding: 0 20px;
  text-align: left;
}

.white-block-content .decoration{
  text-decoration: none;
  color: #001529;
  cursor: pointer;
}

.white-block-content a:hover{
  text-decoration: none;
  color:#3030f7;
}

.application-blocks .white-block{
  margin-bottom: 15px;
}

.small {
  font-size: 14px;
  line-height: 16px;
  margin-top: 10px;
}

.grey-title{
  text-align: left;
  background: #FAFAFA;
  height: 35px;
  border-bottom: 1px solid #e4e2e2;
  margin-bottom: 20px;
  padding: 10px 20px;
}

.grey{
  color: #aeb0b6;
}

p.grey{
  margin: 0;
}

.flex-end{
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: end;
}

.ant-select-multiple .ant-select-selector{
  padding: 5px 4px;
  border-radius: 0px
}

.tab-value{
  max-width: 600px;
  text-align: left;
}

.ant-btn.ant-btn-sm {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ant-empty-description {
	visibility: hidden;
}

.ant-empty-description:before {
	/*content: "Нет данных по вашему запросу";*/
	visibility:visible;
}

.columns-check{
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
}

.ant-picker-clear{
  display: none;
}

.flex-light{
  display: flex;
  gap: 3px;
}

.ant-table-wrapper .ant-table-cell, .ant-table-wrapper .ant-table-thead>tr>th{
  padding: 0 5px;
}

.ant-table-row:has([attr="dangerCell"]) .ant-table-cell{
  background: #FF6A4D;
}

.ant-table-row:has([attr="dangerCell"]) .ant-table-cell:last-child{
  background: white;
}

#message p {
  margin: 0px;
}

/* Добавить зеленый цвет текста и галочка, когда требования верны */
.valid {
  color: green;
}

.valid:before {
  position: relative;
  /*left: -35px;*/
  content: "✔️";
}

/* Добавить красный цвет текста и собой "x" и значок, когда требования не правы */
.invalid {
  color: red;
}

.invalid:before {
  position: relative;
  /*left: -35px;*/
  content: "❌";
}

.ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-title-content {
  flex: none;
}

.ant-table-filter-dropdown-btns .ant-btn-link, .ant-table-filter-dropdown-btns .ant-btn-link:hover{
  color: white!important;
}

.ant-table-filter-dropdown-btns .ant-btn-link::after{
  content: "Сброс";
  color: #1677ff;
}

.half {
  width: 65%;
  margin-right: 200px;
  padding-bottom: 0;
  background: white;
}

.half-lines {
  width: 100%;
  padding-bottom: 0;
  background: white;
}

.half .g2-legend{
  top: 10px!important;
  max-height: 320px!important;
}

.ant-picker-panel:nth-child(2){
  display: none!important;
}
.ant-picker-panel:nth-child(1) .ant-picker-header-next-btn, .ant-picker-panel:nth-child(1) .ant-picker-header-super-next-btn{
  visibility: visible!important;
}

.ant-alert-with-description {
  padding: 10px;
  text-align: left;
  margin-bottom: 10px;
}

.fifty{
  width: 49%!important;
}

.full{
  width: 100%!important;
}

.ant-menu-title-content{
  text-align: left!important;
}

.ant-tabs-top >.ant-tabs-nav {
  margin: 0;
}

.page-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.grid-wrapper {
  flex: 1 1 0px;
}

.greenBackground {
  background-color: #33cc3344;
}

.redFont {
  color: #bb0000;
  font-style: italic;
  text-decoration: underline;
}

.darkGreyBackground {
  background-color: #99999944;
}

.notInExcel {
  color: #1b6d85;
}

.content-overflow{
  overflow: hidden;
  max-width: 400px;
  text-wrap: nowrap;
  text-overflow: ellipsis;
}

table.table-border {
  border-collapse: collapse;
  border: 2px solid rgb(140 140 140);
  font-family: sans-serif;
  font-size: 0.8rem;
  letter-spacing: 1px;
}

.table-border thead {
  background-color: rgb(228 240 245);
}

.table-border th,
.table-border td {
  border: 1px solid rgb(160 160 160);
  padding: 8px 10px;
}

.table-border td:last-of-type {
  text-align: center;
}

.table-border tbody > tr:nth-of-type(even) {
  background-color: rgb(237 238 242);
}

